<template>
  <b-container fluid>
    <bo-page-title />
    <section id="invrelate">
      <b-tabs v-model="tabIndex">
        <b-tab title="Page Settings">
          <bo-card title="Hero Image">
            <b-row>
              <b-col md="4">
                <bo-card-img title="Investor Stock Information" :src="uploader(heroImage.ssf_file, '/assets/images/no-slide.jpg')">
                  <template #buttons>
                    <b-button
                      variant="secondary"
                      size="sm"
                      pill
                      class="btn-icon"
                      v-b-tooltip.hover="'Update'"
                      @click="changeHero()"
                      v-if="moduleRole('updateHero')"
                    >
                      <i class="fas fa-pencil-alt" />
                    </b-button>
                  </template>
                </bo-card-img>
              </b-col>
            </b-row>

            <b-modal 
              v-model="heroEditorOpen"
              :title="'Update Hero Image '+ pageTitle"
              size="lg"
              hide-footer
              body-class="p-0"
            >
              <validation-observer
                v-slot="{ handleSubmit }" ref="VFormHero"
              >
                
                <b-form @submit.prevent="handleSubmit(submitHero)">
                  <b-card-body>
                    <b-row>
                      <b-col md="6">
                        <div class="file-uploader">
                          <label>Hero Image Desktop: </label>
                          <Uploader v-model="heroImage.ssf_file" type="hero-inv-saham"/>
                          <VValidate 
                            name="Hero Image Desktop" 
                            v-model="heroImage.ssf_file"
                            :rules="validationHeroImage.ssf_file" 
                          />
                        </div>
                        
                        <div class="file-uploader">
                          <label>Hero Image Mobile: </label>
                          <Uploader v-model="heroImage.ssf_file_mobile" type="hero-inv-saham-mobile" use-preview />
                          <VValidate 
                            name="Hero Image Mobile" 
                            v-model="heroImage.ssf_file_mobile"
                            :rules="validationHeroImage.ssf_file_mobile" 
                          />
                        </div>
                      </b-col>
                      <b-col md="6">
                        <b-card class="border mb-0">
                          <template #header>
                            <h5 class="card-title">Content (ID)</h5>
                          </template>
                          <b-form-group label-for="heroTitleId">
                            <template #label>Title <strong class="text-danger">*</strong></template>
                            <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" id="heroTitleId" v-model="heroImage.ssf_title_id" @input="autoFill('ssf_alt_mobile_id','ssf_alt_id','ssf_title_id')"/>
                            <VValidate 
                              name="Content ID" 
                              v-model="heroImage.ssf_title_id"
                              :rules="{...validationHeroImage.ssf_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>

                          <b-form-group label-for="altId">
                            <template #label>Alt Image <strong class="text-danger">*</strong></template>
                            <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" id="altId" v-model="heroImage.ssf_alt_id" />
                            <VValidate 
                              name="Alt Image Id" 
                              v-model="heroImage.ssf_alt_id"
                              :rules="{...validationHeroImage.ssf_alt_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                          
                          <b-form-group label-for="altIdM">
                            <template #label>Alt Image Mobile<strong class="text-danger">*</strong></template>
                            <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" id="altIdM" v-model="heroImage.ssf_alt_mobile_id" />
                            <VValidate 
                              name="Alt Image Mobile ID" 
                              v-model="heroImage.ssf_alt_mobile_id"
                              :rules="'required'" 
                            />
                          </b-form-group>
                          
                        </b-card>
                        <b-card class="border mb-0">
                          <template #header>
                            <h5 class="card-title">Content (EN)</h5>
                          </template>
                          <b-form-group label-for="heroTitleEn">
                            <template #label>Title <strong class="text-danger">*</strong></template>
                            <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" id="heroTitleEn" @input="autoFill('ssf_alt_mobile_en','ssf_alt_en','ssf_title_en')" v-model="heroImage.ssf_title_en"/>
                            <VValidate 
                              name="Content EN" 
                              v-model="heroImage.ssf_title_en"
                              :rules="{...validationHeroImage.ssf_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>

                          <b-form-group label-for="altEN">                          
                            <template #label>Alt Image <strong class="text-danger">*</strong></template>
                            <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" id="altEN" v-model="heroImage.ssf_alt_en" />
                            <VValidate 
                              name="Alt Image En" 
                              v-model="heroImage.ssf_alt_en"
                              :rules="{...validationHeroImage.ssf_alt_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                            />
                          </b-form-group>
                          
                          <b-form-group label-for="altEnM">
                            <template #label>Alt Image Mobile<strong class="text-danger">*</strong></template>
                            <b-form-input @keyup="removeWildChar" @keydown="removeWildChar" id="altIEn" v-model="heroImage.ssf_alt_mobile_en" />
                            <VValidate 
                              name="Alt Image Mobile EN" 
                              v-model="heroImage.ssf_alt_mobile_en"
                              :rules="'required'" 
                            />
                          </b-form-group>
                        </b-card>
                      </b-col>
                    </b-row>
                  </b-card-body>
                  <b-card-footer>
                    <div class="text-right">
                      <b-button type="submit" variant="info" class="btn-rounded">Save Changes</b-button>
                    </div>
                  </b-card-footer>
                </b-form>
              </validation-observer>
            </b-modal>
          </bo-card>
        </b-tab>
        <b-tab title="SEO Settings" v-if="moduleRole('updateSEO')">
          <b-card no-body>
            <b-card-header>
              <b-card-title title-tag="h5">Stock Information SEO Settings</b-card-title>
            </b-card-header>
            <validation-observer
              v-slot="{ handleSubmit }" ref="VFormSEO"
            >
            <b-form @submit.prevent="handleSubmit(submitSEO)">
              <b-card-body>
                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Meta Title (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoPageSetting.sss_meta_title_id" />
                      <VValidate 
                        name="Meta Title ID" 
                        v-model="seoPageSetting.sss_meta_title_id"
                        :rules="{...seoValidation.sss_meta_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />   
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Meta Title (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoPageSetting.sss_meta_title_en" />
                      <VValidate 
                        name="Meta Title EN" 
                        v-model="seoPageSetting.sss_meta_title_en"
                        :rules="{...seoValidation.sss_meta_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      />   
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaDesc">
                      <template #label>
                        Meta Description (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-textarea id="homeMetaDesc" rows="5" placeholder="Meta Description" v-model="seoPageSetting.sss_meta_description_id" />
                      <VValidate 
                        name="Meta Description ID" 
                        v-model="seoPageSetting.sss_meta_description_id"
                        :rules="{...seoValidation.sss_meta_description_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      /> 
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaDesc">
                      <template #label>
                        Meta Description (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field acts as the summary for your website\'s page. A good practice is to keep it around 120-155 characters.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-textarea id="homeMetaDesc" rows="5" placeholder="Meta Description" v-model="seoPageSetting.sss_meta_description_en" />
                      <VValidate 
                        name="Meta Description EN" 
                        v-model="seoPageSetting.sss_meta_description_en"
                        :rules="{...seoValidation.sss_meta_description_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" 
                      /> 
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTags">
                      <template #label>
                        Meta Keywords (ID)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-tags id="homeMetaTags" v-model="seoPageSetting.sss_meta_keyword_id" placeholder="Type and press enter ..." remove-on-delete :tag-validator="validatorTags"
                        tag-variant="primary" tag-class="text-white"/>
                    </b-form-group>
                  </b-col>

                  <b-col lg="6">
                    <b-form-group label-for="homeMetaTags">
                      <template #label>
                        Meta Keywords (EN)
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field tell Search Engines what the topic of a page is. Use a relevant and short topic, and make sure to keep only up to 10 keywords.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-tags id="homeMetaTags" v-model="seoPageSetting.sss_meta_keyword_en" placeholder="Type and press enter ..." remove-on-delete :tag-validator="validatorTags"
                        tag-variant="primary" tag-class="text-white"/>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
              <b-card-footer>
                <div class="text-right">
                  <b-button class="btn-rounded" type="submit" variant="primary">Save Changes</b-button>
                </div>
              </b-card-footer>
            </b-form>
            </validation-observer>
          </b-card>
        </b-tab>
      </b-tabs>
      
          <!--
          <bo-card title="IDX - MTWI Stock Information">
            <b-row>
              <b-col md="3" lg="2">
                <div class="mb-3">
                  <div class="stock-info">
                    <div class="stock-price">
                      {{ formatDecimal(data.changes) }}<span class="ml-1">IDR</span>
                    </div>
                    <strong
                      class="stock-info__percent d-flex align-items-center"
                      :class="'text-'+(data.change_in_percent < 0 ? 'danger' : 'success')"
                    >
                      <i
                        :class="'fas fa-arrow-circle-'+(data.change_in_percent < 0 ? 'down' : 'up')"
                        size="16"
                        class="mr-1"
                      />
                      {{ data.change_in_percent }} ({{ data.change_in_percent }}%)</strong>
                  </div>
                  <small>{{data.latest_updated | moment('lll')}} WIB</small>
                </div>
                <div class="mb-3">
                  <strong>Volume</strong>
                  <div>IDR {{ formatDecimal(data.volume) }}</div>
                </div>
                <div class="mb-3">
                  <strong>Value</strong>
                  <div>IDR {{ formatDecimal(data.value) }}</div>
                </div>
                <div class="mb-3">
                  <strong>Frequency</strong>
                  <div>IDR {{ formatDecimal(data.frequency) }}</div>
                </div>
              </b-col>
              <b-col md="9" lg="10">
                <div class="text-center mb-3">
                  <b-button
                    v-for="period in chartTimeModes"
                    :key="period.value"
                    @click="getStock(period.value);chartTime = period.value"
                    :variant="chartTime == period.value ? 'primary' : 'outline-primary'"
                    size="sm"
                    class="mx-1 btn-rounded"
                  >
                    {{ period.label }}
                  </b-button>
                </div>
                <chart class="stock-chart" :option="chartOptions" ref="stockChart" />
              </b-col>
            </b-row>
          </bo-card>
          -->
    </section>
  </b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
// import ECharts from 'vue-echarts'
// import { graphic, use } from 'echarts/core'
// import {
//   CanvasRenderer
// } from 'echarts/renderers'
// import {
//   LineChart
// } from 'echarts/charts'
// import {
//   GridComponent,
//   TooltipComponent,
//   DataZoomComponent,
//   GraphicComponent,
// } from 'echarts/components'
import BoCardImg from '@/components/backend/BoCardImg.vue'
// import axios from 'axios'
import Gen from '@/libs/Gen'
const _ = global._

// use([
//   CanvasRenderer,
//   LineChart,
//   GridComponent,
//   TooltipComponent,
//   DataZoomComponent,
//   GraphicComponent,
// ])

export default {
  extends: GlobalVue,
  
	mounted() {
    this.getStock('D')
    // this.apiGet()
	},
	components: {
  //   'chart': ECharts,\
  BoCardImg
	},
  data() {
    return {
      stockInfo: {"Code":"MTWI","Price":108.0,"Change":8.0,"Percent":8.0,"Volume":55538800.0,"Value":6424083400.0,"Frequency":6417.0,"time":""},
      data:{},
      dataSaham:[],
      
      chartTime: 'D',
      chartTimeModes: [
        { label: 'Today', value: 'D' },
        { label: 'This Week', value: 'W' },
        { label: 'This Month', value: 'M' },
      ],
      tabIndex : 0,
      heroEditorOpen: false,
      heroImage: {},
      validationHeroImage: {},
      seoPageSetting: {},
      seoValidation: {},
    }
  },
  computed: {
    // stockChart() {
    //   return this.$refs.stockChart
    // },
    sidebarState() {
      return this.$store.state.boSidebarOpen
    },
    // chartOptions() {
    //   const sahamChart = this.dataSaham||{}
    //   return {
    //     tooltip: {
    //       trigger: 'axis',
    //     },
    //     color: ['#0e76bd'],
    //     xAxis: {
    //       type: 'category',
    //       data: sahamChart.label||[],
    //     },
    //     yAxis: {
    //       type: 'value',
    //     },
    //     dataZoom: [
    //       {
    //         type: 'slider',
    //         show: true,
    //         xAxisIndex: [0],
    //         start: 1,
    //         end: 35
    //       },
    //       {
    //         type: 'inside',
    //         xAxisIndex: [0],
    //         start: 1,
    //         end: 35
    //       },
    //     ],
    //     grid: {
    //       left: '5%',
    //       right: '3%',
    //       top: 30,
    //       bottom: 30,
    //     },
    //     series: [
    //       {
    //         name: 'Share Price',
    //         type: 'line',
    //         smooth: true,
    //         data: sahamChart.series||[],
    //         areaStyle: {
    //           color: graphic.LinearGradient(0, 0, 0, 1, [
    //             { offset: 0, color: 'rgba(14, 118, 189, .8)' },
    //             { offset: 1, color: 'rgba(14, 118, 189, .25)' },
    //           ])
    //         }
    //       }
    //     ],
    //   }
    // },
  },
  methods: {
    autoFill(to = "", to2 = "", from = ""){
      this.heroImage[to] = this.heroImage[from]
      this.heroImage[to2] = this.heroImage[from]    
    },
    changeHero(){
      this.heroEditorOpen = !this.heroEditorOpen
    },
    formatDecimal(data) {
      return new Intl.NumberFormat('id-ID', ({ style: 'decimal' })).format(data)
    },
    getStock(type){
      this.restAPI({type : type})
    },
    restAPI(params=false, callback=false){
      Gen.apiRest(
        "/get/"+this.modulePage,
        {
          params:params||{}
        }
      ).then(res=>{
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })
        this.loadingOverlay=false
        if(callback) callback()
      })
    },
  },
  watch: {
    $route(){
      this.getStock('D')
      // this.apiGet()
    },
    // counter seo
    'seoPageSetting.sss_meta_title_id'(v){
      let el = document.getElementById('Meta Title ID')
      if(el){
        let showcount = document.getElementById('Meta Title ID' + 'count')
        let cErr = document.getElementById('Meta Title ID' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          el.style.display = "none"
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"        
        }
      }
    },
    'seoPageSetting.sss_meta_title_en'(v){
      let el = document.getElementById('Meta Title EN')
      if(el){
        let showcount = document.getElementById('Meta Title EN' + 'count')
        let cErr = document.getElementById('Meta Title EN' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"          
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_id'(v){
      let el = document.getElementById('Meta Description ID')
      if(el){
        let showcount = document.getElementById('Meta Description ID' + 'count')
        let cErr = document.getElementById('Meta Description ID' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_en'(v){
      let el = document.getElementById('Meta Description EN')
      if(el){
        let showcount = document.getElementById('Meta Description EN' + 'count')
        let cErr = document.getElementById('Meta Description EN' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
    sidebarState() {
      this.stockChart.resize()
    }
  }
}
</script>